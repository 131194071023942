import React from "react"
import {graphql} from "gatsby"
import Layout from "../components/layout"

import formatBOYSEN from "../../lib/formatBoysen"

import ProductInfo from "../components/paintingtools/productinfo"
import ProductDetails from "../components/paintingtools/productdetails"

const ProductlineTemplate = ({pageContext, data}) => {

	const pagedetail = data.detail.edges[0].node.childrenJson[0].info;
	const pagelink = data.detail.edges[0].node.childrenJson[0].linkdata;
	const pagecolor = data.color.edges[0].node.childrenJson;
	const pagetech = data.tech.edges[0].node.childrenJson[0];

	return  (
		<Layout activePath={"products/"+pageContext.item.url} pageImage={pageContext.item.imageurl} pageTitle={formatBOYSEN.stripNameTags(pageContext.item.name+" "+pageContext.item.subtitle)} pageDescription={formatBOYSEN.stripNameTags(pageContext.item.name+" "+pageContext.item.subtitle)}>
			<ProductInfo showpaintcan={true} productdata={pageContext.item} productdetail={pagedetail} productlink={pagelink} />
			<ProductDetails producturl={"products/"+pageContext.item.url} productdetail={pagedetail} productcolor={pagecolor} producttech={pagetech} />
		</Layout>
	)
};

export default ProductlineTemplate

// Accessed as "data"
export const querydata = graphql`
	query ($detail: String!, $color: String!, $tech: String!) {
		detail: allFile(
			filter: {sourceInstanceName: {eq: "productdata"}, name: {eq: $detail} }
		) {
			edges {
				node {
					childrenJson {
						info {
							body
							subtitle
							title
							variant
						}
						linkdata {
							url
							title
						}
					}
				}
			}
		}
		color: allFile(
			filter: {sourceInstanceName: {eq: "productdata"}, name: {eq: $color} }
		) {
			edges {
				node {
					childrenJson {
						jsonId
						code
						name
						variant
						imageurl
						red
						green
						blue
					}
				}
			}
		}
		tech: allFile(
			filter: {sourceInstanceName: {eq: "productdata"}, name: {eq: $tech} }
		) {
			edges {
				node {
					childrenJson {
						variants {
							id
							name
						}
						details {
							name
							info
							idx
						}
					}
				}
			}
		}
	}
`
